.PetImagesContainer {
    display: flex;
    flex-direction: column;
    width: 100vw;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.images,
.videos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.imageModal img {
    object-fit: contain;
    max-width: 90vw;
    max-height: 90vh;
}
