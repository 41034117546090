.ViewerPage {
    background-color: azure;
    width: 100vw;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pswp img {
    object-fit: contain;
}

@media (min-width: 800px) {
    .PetImagesContainer {
        max-width: 75vw;
    }
}