.PortraitView {
    height: 90vh;
    color: white;
    font-size: 2.5vh;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.PortraitView img {
    height: 40vh;
    max-width: 90vw;
    align-self: center;
    object-fit: contain;
}

.PortraitView .PetImage {
    display: flex;
    align-items: center;
    height: 40vh;
    max-width: 90vw;
    align-self: center;
}

.PetName {
    font-size: 4vh;
}

.PetInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;
    margin: .5rem;
}

.footer {
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
    height: 20vh;
    color: #5592d5;
    font-size: 2.5vh;
    margin: .5rem;
}

.logo {
    height: 40vmin;
    pointer-events: none;
    background-color: #282c34;
    height: 15vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: calc(10px + 2vmin);
    color: white;
}