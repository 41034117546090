.LoginContainer {
    justify-content: center;
    flex: 1 1 auto;
    flex-direction: column;
    display: flex;
}

.button {
    border-radius: .5rem;
    margin: 1rem;
}