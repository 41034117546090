.LandscapeView {
    height: 90vh;
    color: white;
    font-size: 3.5vh;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.LeftAndRightSides {
    display: flex;
    flex-direction: row;
    height: 90vh;
}

.logo {
    height: 40vmin;
    pointer-events: none;
    background-color: #282c34;
    height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: calc(10px + 2vmin);
    color: white;
}

.LandscapeView .LeftSide {
    width: 50vw;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.LandscapeView .RightSide {
    width: 30vw;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 2.5rem;
}

.LandscapeView img {
    height: 65vh;
    max-width: 40vw;
    align-self: center;
    margin-top: 1rem;
    object-fit: contain;
}

.PetInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;
}

.footer {
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
    height: 20vh;
    color: #5592d5;
    font-size: 2.5vh;
}