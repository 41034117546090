.UploadPage {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1 1 auto;
    background-color: antiquewhite;
    align-items: center;
}

.UploadPage img {
    margin: 1rem;
}

.DropDownMenus {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 800px) {
        margin: 0 2rem;
        flex-direction: row;
        .LocationDropDown {
            width: 20%;
            margin-left: 2rem;
        }

        .PetDropDown {
            margin-left: 2rem;
            width: 50%;
        }
    }
}

.PetDropDownContainer {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}