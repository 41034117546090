.UploaderComponent {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.UploaderComponent h3 {
    margin: .5rem;
}

.buttons button {
    margin: .5rem;
}