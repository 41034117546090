.App {
    text-align: center;
    min-height: 100vh;
    max-height: 100vh;
    width: 100vw;
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.App-header img {
    height: 10vh;
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}

